/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import LogRocket from "logrocket"

const onClientEntry = () => {
  console.log("We've started!")
  LogRocket.init("2cevqg/beauty-gems")
}

export { onClientEntry }
