// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-book-online-js": () => import("./../../../src/pages/book-online.js" /* webpackChunkName: "component---src-pages-book-online-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-treatments-eyelash-extensions-js": () => import("./../../../src/pages/treatments/eyelash-extensions.js" /* webpackChunkName: "component---src-pages-treatments-eyelash-extensions-js" */),
  "component---src-pages-treatments-js": () => import("./../../../src/pages/treatments.js" /* webpackChunkName: "component---src-pages-treatments-js" */),
  "component---src-pages-treatments-lash-lift-js": () => import("./../../../src/pages/treatments/lash-lift.js" /* webpackChunkName: "component---src-pages-treatments-lash-lift-js" */),
  "component---src-pages-treatments-shellac-js": () => import("./../../../src/pages/treatments/shellac.js" /* webpackChunkName: "component---src-pages-treatments-shellac-js" */)
}

